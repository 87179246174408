<template>
  <div class="card">
    <div class="table-responsive">
      <h4>Daily Sales-Collection Summary</h4>
      <table class="table-bordered text-right">
        <tr>
          <th class="text-left">Particulars</th>
          <th>Amount</th>
        </tr>
        <template v-for="(childs, parentKey) in groupedData" :key="parentKey">
          <tr>
            <th class="text-left" colspan="2">{{ parentKey }}</th>
          </tr>
          <tr v-for="(child, index) in childs" :key="index">
            <td class="text-left">{{ child.name }}</td>
            <td>{{ commaFormat(child.amount) }}</td>
          </tr>
          <tr>
            <th class="text-left">Total {{ parentKey }}</th>
            <th>{{ commaFormat(sumTotalParentGroupAmount(childs)) }}</th>
          </tr>
        </template>
        <tr>
          <th class="text-left">Gross Income: </th>
          <th>{{ commaFormat(getTotalAmount(lists) - getTotalReturn(lists)) }}</th>
        </tr>
        <tr>
          <td colspan="2"></td>
        </tr>
        <tr>
          <th class="text-left" colspan="2">Receivable Adjustment</th>
        </tr>
        <tr>
          <td class="text-left">(Add) Advance Money Received: </td>
          <td>{{ commaFormat(getTotalAdvance()) }}</td>
        </tr>
        <tr>
          <td class="text-left">(Less) Advance Adjustment: </td>
          <td>{{ commaFormat(getTotalIpdBill('sum_advance_adj')) }}</td>
        </tr>
        <tr>
          <td class="text-left">(Add) Previous Bill Adjustment: </td>
          <td>{{ commaFormat(getTotalPreviousBill()) }}</td>
        </tr>
        <tr>
          <td class="text-left">(Less) Today's Due: </td>
          <td>{{ commaFormat(getTotalDue()) }}: </td>
        </tr>
        <tr>
          <td class="text-left">(Less) Net Discount: </td>
          <td>
            {{
              commaFormat(getTotalDiscount(lists) + sumTotalSalesAmount(ipdSettlementReceipts, 'discount_amount') - getTotalReturnDiscount(lists))
            }}
          </td>
        </tr>
        <tr>
          <th class="text-left">Total Receivable Adjustment</th>
          <th>
            {{
              commaFormat((getTotalAdvance()+getTotalPreviousBill()) - (getTotalIpdBill('sum_advance_adj') + getTotalDue() + getTotalDiscount(lists) + sumTotalSalesAmount(ipdSettlementReceipts, 'discount_amount') - getTotalReturnDiscount(lists)))
            }}
          </th>
        </tr>
        <tr>
          <th class="text-left">Net Collection: </th>
          <th>
            {{
              commaFormat(((getTotalAmount(lists) - getTotalReturn(lists)) - (getTotalDiscount(lists) + sumTotalSalesAmount(ipdSettlementReceipts, 'discount_amount') - getTotalReturnDiscount(lists)) - getTotalDue() - getTotalIpdBill('sum_advance_adj')) + getTotalAdvance() + getTotalPreviousBill())
            }}
          </th>
        </tr>
        <tr>
          <td colspan="2"></td>
        </tr>
        <tr>
          <td class="text-left">Received Amount: </td>
          <td>{{ commaFormat(getTotalReceipt()) }}</td>
        </tr>
        <tr>
          <td class="text-left">Refund Amount: </td>
          <td>{{ commaFormat(getTotalRefund()) }}</td>
        </tr>

        <tr>
          <td class="text-left">Check: </td>
          <td>
            {{
              commaFormat((((getTotalAmount(lists) - getTotalReturn(lists)) - (getTotalDiscount(lists) + sumTotalSalesAmount(ipdSettlementReceipts, 'discount_amount') - getTotalReturnDiscount(lists)) - getTotalDue() - getTotalIpdBill('sum_advance_adj')) + getTotalAdvance() + getTotalPreviousBill()) + getTotalRefund() - getTotalReceipt())
            }}
          </td>
        </tr>
      </table>
      <table class="table table-bordered">
        <thead>
        <tr>
          <th>Invoice Date</th>
          <th>Invoice no</th>
          <th>Bill Type</th>
          <th>Credit Note Date</th>
          <th>Credit Note No</th>
          <th>MR Date</th>
          <th>MR No.</th>
          <th>Patient Name</th>
          <th>Patient ID</th>
          <th>Patient Mobile No</th>
          <th>Admission No</th>
          <th class="text-right">Bill</th>
          <th class="text-right">Bill Discount</th>
          <th class="text-right">Sales Return</th>
          <th class="text-right">Return Discount</th>

          <th class="text-right">Advance MR</th>
          <th class="text-right">Previous Bill</th>
          <th class="text-right">Today's IPD Bill</th>
          <th class="text-right">IPD Settl Discount</th>
          <th class="text-right">Advance Adj</th>

          <th class="text-right">Receipt</th>
          <th class="text-right">Refund</th>
          <th class="text-right">Net Collection</th>
          <th class="text-right">Due</th>
          <th v-for="groupName in groupNames">{{ groupName.name }}</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td colspan="100%" class="bg-gradient-secondary">Today's Bill</td>
        </tr>
        <tr v-for="(item, i) in lists" :key="i">
          <td>{{ item.date }}</td>
          <td>{{ item.bill_number }}</td>
          <td>{{ item.sale_type }}</td>
          <td>
            <span class="badge bg-secondary" v-for="saleReturnMaster in item.sale_return_masters">
              {{ saleReturnMaster.date }}
            </span>
          </td>
          <td>
            <span class="badge bg-secondary" v-for="saleReturnMaster in item.sale_return_masters">
              {{ saleReturnMaster.bill_number }}
            </span>
          </td>
          <td>
            <span v-for="receiptMaster in item.receipt_masters" class="badge bg-secondary">
            {{ receiptMaster.receipt_date }}
          </span>
          </td>
          <td>
            <span class="badge bg-secondary" v-for="receiptMaster in item.receipt_masters">
            {{ receiptMaster.voucher_no }}
          </span>
          </td>
          <td>{{ item.contact_profile.full_name }}</td>
          <td>{{ item.contact_profile.patient_id }}</td>
          <td>{{ item.contact_profile.mobile_no }}</td>
          <td>{{ item.admission_no }}</td>
          <td class="text-right">{{ commaFormat(item.subtotal_amount) }}</td>
          <td class="text-right">{{ commaFormat(item.total_discount) }}</td>
          <td class="text-right">{{ commaFormat(item.sales_return_amount) }}</td>
          <td class="text-right">{{ commaFormat(item.sales_return_discount_amount) }}</td>
          <td colspan="5"></td>
          <td class="text-right">{{ commaFormat(sumTotalSalesAmount(item.receipt_masters, 'paid_amount')) }}</td>
          <td class="text-right">{{ commaFormat(sumTotalSalesAmount(item.receipt_masters, 'refund_amount')) }}</td>
          <td class="text-right">
            {{
              commaFormat(sumTotalSalesAmount(item.receipt_masters, 'paid_amount') - sumTotalSalesAmount(item.receipt_masters, 'refund_amount'))
            }}
          </td>
          <td class="text-right">
            {{
              commaFormat((item.subtotal_amount - item.total_discount - sumTotalSalesAmount(item.receipt_masters, 'paid_amount')) - (item.sales_return_amount - item.sales_return_discount_amount - sumTotalSalesAmount(item.receipt_masters, 'refund_amount')))
            }}
          </td>
          <td v-for="(groupName, groupId) in groupNames">
            {{ getGroupAmount(groupId, item.grouped_general) }}
          </td>
        </tr>

        <tr>
          <td colspan="100%" class="bg-gradient-secondary">Today's Sale Return (Different users)</td>
        </tr>
        <tr v-for="(item, i) in saleReturns" :key="i">
          <td>{{ item.sale_master.date }}</td>
          <td>{{ item.sale_master.bill_number }}</td>
          <td>{{ item.sale_master.sale_type }}</td>
          <td>{{ item.date }}</td>
          <td>{{ item.bill_number }}</td>
          <td colspan="2"></td>
          <td>{{ item.contact_profile.full_name }}</td>
          <td>{{ item.contact_profile.patient_id }}</td>
          <td>{{ item.contact_profile.mobile_no }}</td>
          <td colspan="3"></td>
          <td class="text-right">{{ commaFormat(item.subtotal_amount) }}</td>
          <td class="text-right">{{ commaFormat(item.total_discount) }}</td>
          <td colspan="6"></td>
          <td class="text-right">{{ commaFormat(item.total_amount) }}</td>
          <td class="text-right">{{ commaFormat(0 - (item.total_amount)) }}</td>
          <td class="text-right">0</td>
          <td v-for="(groupName, groupId) in groupNames">
            {{ getGroupAmount(groupId, item.grouped_general) }}
          </td>
        </tr>

        <tr>
          <td colspan="100%" class="bg-gradient-secondary">Return from previous bill</td>
        </tr>
        <tr v-for="(item, i) in saleReturnPreviousBills" :key="i">
          <td>{{ item.sale_master.date }}</td>
          <td>{{ item.sale_master.bill_number }}</td>
          <td>{{ item.sale_master.sale_type }}</td>
          <td>{{ item.date }}</td>
          <td>{{ item.bill_number }}</td>
          <td colspan="2"></td>
          <td>{{ item.contact_profile.full_name }}</td>
          <td>{{ item.contact_profile.patient_id }}</td>
          <td>{{ item.contact_profile.mobile_no }}</td>
          <td colspan="3"></td>
          <td class="text-right">{{ commaFormat(item.subtotal_amount) }}</td>
          <td class="text-right">{{ commaFormat(item.total_discount) }}</td>
          <td colspan="6"></td>
          <td class="text-right">{{ commaFormat(item.total_amount) }}</td>
          <td class="text-right">{{ commaFormat(0 - (item.total_amount)) }}</td>
          <td class="text-right">0</td>
          <td v-for="(groupName, groupId) in groupNames">
            {{ getGroupAmount(groupId, item.grouped_general) }}
          </td>
        </tr>

        <tr>
          <td colspan="100%" class="bg-gradient-secondary">IPD Final Settlement - Money Receipt</td>
        </tr>
        <tr v-for="(item, i) in ipdSettlementReceipts" :key="i">
          <td colspan="2"></td>
          <td>IPD Settlement</td>
          <td colspan="2"></td>
          <td>{{ item.receipt_date }}</td>
          <td>{{ item.voucher_no }}</td>
          <td>{{ item.contact_profile.full_name }}</td>
          <td>{{ item.contact_profile.patient_id }}</td>
          <td>{{ item.contact_profile.mobile_no }}</td>
          <td colspan="6"></td>
          <td class="text-right">
            {{
              commaFormat(item.ipd_register.previous_sale_master_bill)
            }}
          </td>
          <td class="text-right">{{ commaFormat(item.ipd_register.today_idp_bill) }}</td>
          <td class="text-right">{{ commaFormat(item.discount_amount) }}</td>
          <td class="text-right">{{ commaFormat(item.ipd_register.sum_advance_adj) }}</td>
          <td class="text-right">{{ commaFormat(item.total_paid_amount) }}</td>
          <td class="text-right">{{ item.refund_amount }}</td>
          <td class="text-right">{{ commaFormat(item.total_paid_amount - item.refund_amount) }}</td>
          <td class="text-right">{{ commaFormat(0 - item.ipd_register.today_idp_bill) }}</td>
          <td v-if="groupNames.length" :colspan="groupNames.length"></td>
        </tr>

        <tr>
          <td colspan="100%" class="bg-gradient-secondary">Previous bill - Money Receipt</td>
        </tr>
        <tr v-for="(item, i) in previousBillReceipts" :key="i">
          <td colspan="2"></td>
          <td>{{ item.receipt_type }}</td>
          <td colspan="2"></td>
          <td>{{ item.receipt_date }}</td>
          <td>{{ item.voucher_no }}</td>
          <td>{{ item.contact_profile.full_name }}</td>
          <td>{{ item.contact_profile.patient_id }}</td>
          <td>{{ item.contact_profile.mobile_no }}</td>
          <td colspan="6"></td>
          <td class="text-right">{{ commaFormat(item.total_paid_amount) }}</td>
          <td colspan="3"></td>
          <td class="text-right">{{ commaFormat(item.total_paid_amount) }}</td>
          <td></td>
          <td class="text-right">{{ commaFormat(item.total_paid_amount) }}</td>
          <td class="text-right">0</td>
          <td v-if="groupNames.length" :colspan="groupNames.length"></td>
        </tr>

        <tr>
          <td colspan="100%" class="bg-gradient-secondary">Advance - Money Receipt</td>
        </tr>
        <tr v-for="(item, i) in advanceReceipts" :key="i">
          <td colspan="2"></td>
          <td>Advance</td>
          <td colspan="2"></td>
          <td>{{ item.receipt_date }}</td>
          <td>{{ item.voucher_no }}</td>
          <td>{{ item.contact_profile.full_name }}</td>
          <td>{{ item.contact_profile.patient_id }}</td>
          <td>{{ item.contact_profile.mobile_no }}</td>
          <td colspan="5"></td>
          <td class="text-right">{{ commaFormat(item.total_paid_amount) }}</td>
          <td colspan="4"></td>
          <td class="text-right">{{ commaFormat(item.total_paid_amount) }}</td>
          <td></td>
          <td class="text-right">{{ commaFormat(item.total_paid_amount) }}</td>
          <td class="text-right">0</td>
          <td v-if="groupNames.length" :colspan="groupNames.length"></td>
        </tr>

        </tbody>
        <tfoot>
        <tr>
          <th class="text-center" colspan="11">Total</th>
          <th class="text-right">{{ commaFormat(getTotalAmount(lists)) }}</th>
          <th class="text-right">{{ commaFormat(getTotalDiscount(lists)) }}</th>
          <th class="text-right">{{ commaFormat(getTotalReturn(lists)) }}</th>
          <th class="text-right">{{ commaFormat(getTotalReturnDiscount(lists)) }}</th>
          <th class="text-right">{{ commaFormat(getTotalAdvance()) }}</th>
          <th class="text-right">{{ commaFormat(getTotalPreviousBill()) }}</th>
          <th class="text-right">{{ commaFormat(getTotalIpdBill('today_idp_bill')) }}</th>
          <th class="text-right">{{ commaFormat(sumTotalSalesAmount(ipdSettlementReceipts, 'discount_amount')) }}</th>
          <th class="text-right">{{ commaFormat(getTotalIpdBill('sum_advance_adj')) }}</th>
          <th class="text-right">{{ commaFormat(getTotalReceipt()) }}</th>
          <th class="text-right">{{ commaFormat(getTotalRefund()) }}</th>
          <th class="text-right">{{
              commaFormat(((getTotalAmount(lists) - getTotalReturn(lists)) - (getTotalDiscount(lists) + sumTotalSalesAmount(ipdSettlementReceipts, 'discount_amount') - getTotalReturnDiscount(lists)) - getTotalDue() - getTotalIpdBill('sum_advance_adj')) + getTotalAdvance() + getTotalPreviousBill())
            }}</th>
          <th class="text-right">{{ commaFormat(getTotalDue()) }}</th>
          <th v-for="groupName in groupNames">{{ commaFormat(groupName.amount) }}</th>
        </tr>
        </tfoot>
      </table>
    </div>
  </div>
</template>

<script setup>
import figureFormatter from "@/services/utils/figureFormatter";
import {computed} from "vue";

const props = defineProps({
  lists: Array,
  groupNames: Object,
  finalTotal: Object,
  saleReturns: {
    type: Array,
    default: []
  },
  saleReturnPreviousBills: {
    type: Array,
    default: []
  },
  ipdSettlementReceipts: {
    type: Array,
    default: []
  },
  previousBillReceipts: {
    type: Array,
    default: []
  },
  advanceReceipts: {
    type: Array,
    default: []
  }
})

const {commaFormat} = figureFormatter()

// Group data by parent_name
const groupedData = computed(() => {
  const grouped = {};
  Object.values(props.groupNames).forEach(item => {
    const parentName = item.parent_name;
    if (!grouped[parentName]) {
      grouped[parentName] = [];
    }
    grouped[parentName].push(item);
  });
  return grouped;
});

const sumTotalParentGroupAmount = childs => childs.reduce((total, child) => total + child.amount, 0);

const getGroupAmount = (groupId, grouped_general) => {
  const findGroupData = grouped_general.find((item) => item.product_group_id == groupId)
  if (findGroupData) {
    return commaFormat(findGroupData.total_amount)
  }
  return 0
}

const getTotalAmount = (items) => {
  return items.reduce((total, i) => total + i.subtotal_amount, 0);
}

const getTotalDiscount = (items) => {
  return items.reduce((total, i) => total + i.total_discount, 0);
}

const getTotalReturn = (items) => {
  const total = items.reduce((total, i) => total + i.sales_return_amount, 0)
  const saleReturn = props.saleReturns.reduce((total, i) => total + i.subtotal_amount, 0)
  const saleReturnPreviousBills = props.saleReturnPreviousBills.reduce((total, i) => total + i.subtotal_amount, 0)

  return total + saleReturn + saleReturnPreviousBills
}

const getTotalReturnDiscount = (items) => {
  const total = items.reduce((total, i) => total + i.sales_return_discount_amount, 0)
  const saleReturn = props.saleReturns.reduce((total, i) => total + i.total_discount, 0)
  const saleReturnPreviousBills = props.saleReturnPreviousBills.reduce((total, i) => total + i.total_discount, 0)
  return total + saleReturn + saleReturnPreviousBills
}

const getTotalAdvance = () => {
  return props.advanceReceipts.reduce((total, i) => total + i.total_paid_amount, 0)
}

const getTotalPreviousBill = () => {
  const ipdTotal = props.ipdSettlementReceipts.reduce((total, i) => total + i.ipd_register.previous_sale_master_bill, 0)
  const previousTotal = props.previousBillReceipts.reduce((total, i) => total + i.total_paid_amount, 0)
  return ipdTotal + previousTotal
}

const getTotalReceipt = () => {
  let saleTotal = 0
  props.lists.forEach((item) => {
    saleTotal += sumTotalSalesAmount(item.receipt_masters, 'paid_amount')
  })

  const ipdTotal = props.ipdSettlementReceipts.reduce((total, i) => total + i.total_paid_amount, 0)
  const previousTotal = props.previousBillReceipts.reduce((total, i) => total + i.total_paid_amount, 0)
  const advanceTotal = props.advanceReceipts.reduce((total, i) => total + i.total_paid_amount, 0)
  return saleTotal + ipdTotal + previousTotal + advanceTotal
}

const getTotalRefund = () => {
  let saleTotal = 0
  props.lists.forEach((item) => {
    saleTotal += sumTotalSalesAmount(item.receipt_masters, 'refund_amount')
  })

  const saleReturn = props.saleReturns.reduce((total, i) => total + i.total_amount, 0)
  const saleReturnPreviousBills = props.saleReturnPreviousBills.reduce((total, i) => total + i.total_amount, 0)

  const ipdTotal = props.ipdSettlementReceipts.reduce((total, i) => total + i.refund_amount, 0)
  return saleTotal + saleReturn + saleReturnPreviousBills + ipdTotal
}

const getTotalDue = () => {
  let saleTotal = 0
  props.lists.forEach((item) => {
    saleTotal += (item.subtotal_amount - item.total_discount - sumTotalSalesAmount(item.receipt_masters, 'paid_amount')) - (item.sales_return_amount - item.sales_return_discount_amount - sumTotalSalesAmount(item.receipt_masters, 'refund_amount'))
  })

  let ipdTotal = 0
  props.ipdSettlementReceipts.forEach((item) => {
    ipdTotal += 0 - item.ipd_register.today_idp_bill
  })

  return saleTotal + ipdTotal
}

const getTotalIpdBill = (column) => {
  return props.ipdSettlementReceipts.reduce((total, i) => total + i.ipd_register[column], 0)
}

const sumTotalSalesAmount = (items, column) => {
  return items.reduce((total, i) => total + i[column], 0);
}
</script>
